import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import styled from "styled-components";

const HISTORY_STORAGE_KEY = "btcQueryHistory";

const RecentSearches = styled.h3`
  margin-bottom: 0;
`;

const BtcSearchPage = styled.div`
  margin-top: 1rem;
  padding: 0 1.53rem !important;
`;

const FieldSet = styled.fieldset`
  overflow: auto;
  min-width: auto;
`;

const BtcSearch = () => {
  const [formData, setFormData] = useState({
    address: "",
  });
  let navigate = useNavigate();

  useEffect(() => {
    document.title = "d2index | BTC Explorer";
  }, []);

  const feelingLucky = () => {
    // TODO: do a random address from a list of interesting wallets
    // using Binance hot wallet for now
    navigate(`/btc-explorer/bc1qm34lsc65zpw79lxes69zkqmk6ee3ewf0j77s3h`);
  };

  const recentSearches =
    JSON.parse(localStorage.getItem(HISTORY_STORAGE_KEY)) || [];

  return (
    <BtcSearchPage>
      <form
        onSubmit={async (event) => {
          event.preventDefault();
          const query = formData.address;
          console.log("searched for:", query);

          // saving to local storage
          const history =
            JSON.parse(localStorage.getItem(HISTORY_STORAGE_KEY)) || [];
          if (!history.find((x) => x === query)) {
            localStorage.setItem(
              HISTORY_STORAGE_KEY,
              JSON.stringify([query, ...history])
            );
          }

          navigate(`/btc-explorer/${query}`);
        }}
      >
        <FieldSet>
          <legend>Bitcoin Explorer</legend>

          <div className="form-group">
            <label className="terminal-prompt" htmlFor="btcAddress">
              Enter wallet or tx hash:
            </label>
            <input
              id="btcAddress"
              name="btcAddress"
              type="text"
              required=""
              minLength="1"
              // satoshi's address? easter egg
              // placeholder="1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa"
              onChange={(e) =>
                setFormData({ ...formData, address: e.target.value })
              }
              value={formData.address}
            />
          </div>

          <p>
            You can also lookup multiple wallet addresses using a pipe
            delimiter. Ex: addressOne|addressTwo
          </p>

          <div className="form-group">
            <button
              className="btn btn-default"
              type="submit"
              role="button"
              name="submit"
              id="submit"
            >
              Submit
            </button>
            <button className="btn btn-primary" onClick={feelingLucky}>
              I'm Feeling Lucky
            </button>
          </div>
          <div className="form-group">
            <RecentSearches>Recent Searches</RecentSearches>
            <ul>
              {recentSearches.map((x) => (
                <li key={x}>
                  <Link to={x}>{x}</Link>
                </li>
              ))}
            </ul>
          </div>
        </FieldSet>
      </form>
    </BtcSearchPage>
  );
};

export default BtcSearch;
