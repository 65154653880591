import { useState, useEffect } from "react";
import { FiShare } from "react-icons/fi";
import {
  SlSocialTwitter,
  SlSocialFacebook,
  SlSocialReddit,
  SlDocs,
} from "react-icons/sl";

import { FiCopy } from "react-icons/fi";

import { AiOutlineCloseSquare } from "react-icons/ai";
import { Row, Col } from "react-flexbox-grid";

import {
  FacebookIcon,
  FacebookShareButton,
  RedditShareButton,
  RedditIcon,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import { RxCopy } from "react-icons/rx";
import styled from "styled-components";

const Container = styled.div`
  height: 2.7rem;
  margin: 0 0 0.2rem 0;
  width: 8.3rem;
  display: flex;
  flex-direction: row;

  @media (max-width: 650px) {
  }
`;

const ShareDropDown = styled.ul`
  background-color: #333339;
  z-index: 10000 !important;
  display: flex;
  flex-direction: column;
  left: 0;
  margin: 0;
  li:after {
    content: "";
  }

  li {
    padding: 0.7rem;
    margin: 0 !important;
    display: flex;
    align-items: center;

    svg {
      align-items: center;
      margin: 0.2rem 0.2rem 0 0;
      height: 1rem;
      width: 1rem;
    }

    &:hover {
      svg {
        background-color: #333339;
        color: #ffb000;
      }
    }
  }
`;

const ClickShare = styled.span`
  display: flex;
  padding: 0.7rem;
  &:hover {
    background-color: #333339;
  }
  @media (max-width: 650px) {
    padding: 0.7rem;
  }
`;

const ShareIcon = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  position: absolute;
  margin: 0 auto 0.3rem auto;
  width: 8.3rem;
  display: flex;
  text-align: center;
  font-size: 0.9rem;
  background-color: #222225;

  &:hover {
    background-color: #333339;
  }

  @media (max-width: 650px) {
  }
`;
const AlertHidden = styled.div``;

const Alert = styled.div`
  bottom: 20px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 1rem;
  width: 50%;
  position: fixed;
  color: #1a95e0;
  background-color: #333339;
  border: 1px solid #1a95e0;
  border-radius: 0px;
  padding: 0.5rem 1rem;
  z-index: 1000;
  font-size: 1rem;
  font-weight: 300;
`;

const ShareButton = ({ story }) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const [closeClicked, setCloseClicked] = useState(false);

  const [shareClicked, setShareClicked] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const style1 = { marginLeft: ".5rem" };
  const style2 = { marginTop: ".15rem" };

  const handleShare = () => {
    if (shareClicked) {
      setShareClicked(false);
    } else {
      setShareClicked(true);
    }
  };
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  // copies the link and also deals with close state of alert popup
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    if (copySuccess) {
      setCopySuccess(false);
      setCloseClicked(true);
    } else {
      setCopySuccess(true);
      setCloseClicked(false);
    }
  };

  return (
    <>
      {" "}
      <Container>
        <ShareIcon
          style={{ backgroundColor: shareClicked ? "#333339" : "#222225" }}
          onClick={() => handleShare()}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <ClickShare>
            Share <FiShare style={{ ...style1, ...style2 }} />{" "}
          </ClickShare>

          <>
            <ShareDropDown style={{ display: shareClicked ? "flex" : "none" }}>
              {" "}
              <li onClick={() => handleShare()}>
                <FacebookShareButton url={story.storyUrl}>
                  {" "}
                  <SlSocialFacebook />
                  Facebook
                </FacebookShareButton>{" "}
              </li>
              <li onClick={() => handleShare()}>
                <TwitterShareButton url={story.storyUrl}>
                  {" "}
                  <SlSocialTwitter />
                  Twitter
                </TwitterShareButton>{" "}
              </li>
              <li onClick={() => handleShare()}>
                <RedditShareButton url={story.storyUrl}>
                  <SlSocialReddit />
                  Reddit
                </RedditShareButton>{" "}
              </li>{" "}
              <li onClick={() => handleCopy(story.storyUrl)}>
                <RxCopy />
                Copy Link
              </li>
            </ShareDropDown>
          </>
        </ShareIcon>
      </Container>
      {copySuccess &&
        (closeClicked ? (
          <AlertHidden>
            Link Copied{" "}
            <AiOutlineCloseSquare
              size={25}
              onClick={() => handleCopy(story.storyUrl)}
              isCloseClicked={!closeClicked}
              none
              style={{ cursor: "pointer" }}
            />
          </AlertHidden>
        ) : (
          <Alert>
            Link Copied{" "}
            <AiOutlineCloseSquare
              size={25}
              onClick={() => handleCopy(story.storyUrl)}
              isCloseClicked={!closeClicked}
              none
              style={{ cursor: "pointer" }}
            />
          </Alert>
        ))}
    </>
  );
};

export default ShareButton;
