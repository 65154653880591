import React from "react";
import fillerimage1 from "./fillerimages/fillerimage1.jpg";
import { useState, useEffect } from "react";
import styled from "styled-components";
import ShareButton from "./ShareButton";
import { TopStoryImageContainer, Image, NewsHeader } from "./sharedStyleds";

import { API_URL } from "./config";

const TopStoriesItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.8rem 0.4rem;
  background-color: rgba(34, 34, 37);
  border: 1px solid #e8e9ed;
  position: relative;
  width: fit-content;

  border-radius: 5px;

  @media (min-width: 768px) {
    ${"" /* TODO: fix height of top stories */}
    height: 375px;
  }
  @media (max-width: 650px) {
  }
  @media (max-width: 400px) {
    font-size: 0.8rem;
    font-weight: 0;
  }
`;

const NewsDatePosted = styled.p`
  width: 100%;
  padding: 0 !important;
  margin: 0.4rem 0.4rem 0.1rem 0.4rem !important;
  height: 25px;
  @media (max-width: 650px) {
    font-size: 0.9rem;
  }

  @media (max-width: 400px) {
    font-size: 0.7rem;
  }
`;

const NewsIcons = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  padding: 0.3rem 0.5rem 0.2rem 0.5rem;
  height: 65px;

  @media (max-width: 991px) {
  }
  @media (max-width: 767px) {
    padding: 0.3rem 0.3rem 0.2rem 0.3rem;
  }
  @media (max-width: 650px) {
    padding: 0.2rem 0.2rem 0.2rem 0.2rem;

    margin-bottom: 0.1rem;
  }

  @media (max-width: 350px) {
    padding-right: 0rem;
  }
`;

const TopStories = ({ story }) => {
  const domain = new URL(story.storyUrl).hostname;
  return (
    <TopStoriesItem key={story.d2Id}>
      <TopStoryImageContainer
        href={String(story.storyUrl)}
        target="_blank"
        rel="noreferrer"
      >
        {story.imageUrl ? (
          <Image src={story.imageUrl} />
        ) : (
          <Image src={fillerimage1} />
        )}
      </TopStoryImageContainer>
      <NewsHeader>
        <a href={String(story.storyUrl)} target="_blank" rel="noreferrer">
          {story.title} ({domain})
        </a>
      </NewsHeader>
      <NewsDatePosted>
        posted {new Date(story.postedAt).toLocaleString()}
      </NewsDatePosted>
      <NewsIcons>
        {/* TODO: disabling until favoriting is implemented */}
        {/* <ScoreContainer story={x} score={x.d2Score} id={x.d2Id} /> */}

        <ShareButton story={story} />
      </NewsIcons>
    </TopStoriesItem>
  );
};

export default TopStories;
