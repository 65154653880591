import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import styled from "styled-components";
import LoadingBar from "./LoadingBar";
import { fmtUsd, satsToBtc, truncateHash, btcToString } from "./utils";
import { BtcBalPara, UsdBalPara } from "./sharedStyleds";
import { Link } from "react-router-dom";

const DetailP = styled.p`
  margin: 0;
`;

const Section = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const SummaryRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const SummaryCol = styled.div`
  min-width: 50%;
`;

const FullTxDetails = ({ txData, btcPrice }) => {
  const { fee, hash, inputs, out, size, time, block_height, block_index } =
    txData;

  const inputTotalBtc = satsToBtc(
    inputs.reduce((prev, cur) => prev + cur.prev_out.value, 0)
  );
  const inputTotalBtcStr = btcToString(inputTotalBtc);
  const inputTotalInUsdStr = fmtUsd(inputTotalBtc * btcPrice);

  const outputTotalBtc = satsToBtc(
    out.reduce((prev, cur) => prev + cur.value, 0)
  );
  const outputTotalBtcStr = btcToString(outputTotalBtc);
  const outputTotalInUsdStr = fmtUsd(outputTotalBtc * btcPrice);

  // TODO: compare with latest block
  const isConfirmed = !!block_height;

  const feeInBtc = satsToBtc(fee);
  const feeInBtcStr = btcToString(feeInBtc);
  const feeInUsdStr = fmtUsd(feeInBtc * btcPrice);

  return (
    <>
      <SummaryRow>
        <SummaryCol>
          <DetailP>
            Fee: {feeInBtcStr} BTC (${feeInUsdStr})
          </DetailP>
          <DetailP>Size: {size} bytes</DetailP>
          <DetailP>Sent at: {new Date(time * 1000).toLocaleString()}</DetailP>
        </SummaryCol>
        <SummaryCol>
          <BtcBalPara>{outputTotalBtcStr} BTC</BtcBalPara>
          <UsdBalPara>${outputTotalInUsdStr}</UsdBalPara>
          <DetailP>{isConfirmed ? "Confirmed" : "Unconfirmed"}</DetailP>
        </SummaryCol>
      </SummaryRow>
      <DetailP>Hash: {hash}</DetailP>
      <Section></Section>
      <Section>
        <strong>
          Inputs - {inputTotalBtcStr} BTC (${inputTotalInUsdStr}):
        </strong>
        {inputs.map((x) => {
          const addr = x.prev_out.addr;
          const btcAmt = satsToBtc(x.prev_out.value);
          const btcAmtFmt = btcToString(btcAmt);
          const amtInUsdFmt = fmtUsd(btcAmt * btcPrice);
          return (
            <DetailP key={addr}>
              <Link to={`/btc-explorer/${addr}`}>{addr}</Link> {btcAmtFmt} BTC
              (${amtInUsdFmt})
            </DetailP>
          );
        })}
      </Section>
      <Section>
        <strong>
          Outputs - {outputTotalBtcStr} BTC (${outputTotalInUsdStr}):
        </strong>
        {out.map((x) => {
          const addr = x.addr;
          const btcAmt = satsToBtc(x.value);
          const btcAmtFmt = btcToString(btcAmt);
          const amtInUsdFmt = fmtUsd(btcAmt * btcPrice);
          return (
            <DetailP key={addr}>
              <Link to={`/btc-explorer/${addr}`}>{addr}</Link> {btcAmtFmt} BTC
              (${amtInUsdFmt})
            </DetailP>
          );
        })}
      </Section>
    </>
  );
};

export default FullTxDetails;
