import { configureStore } from "@reduxjs/toolkit";
import { cryptoCompareApi } from "../services/cryptoCompareApi";
import { d2indexApi } from "../services/d2indexApi";
import { setupListeners } from "@reduxjs/toolkit/query";

import timeReducer from "./slices/timeCustomSlice";
import cryptoDataReducer from "./slices/chartSymbolSlice";

export const store = configureStore({
  reducer: {
    [cryptoCompareApi.reducerPath]: cryptoCompareApi.reducer,
    time: timeReducer,
    cryptocurrencyData: cryptoDataReducer,
    [d2indexApi.reducerPath]: d2indexApi.reducer,
  },
});

setupListeners(store.dispatch);
