import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { cryptocurrencies } from "./cryptocurrencies";

import styled from "styled-components";
import { AiOutlineSearch } from "react-icons/ai";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setChartSymbol,
  setCoinName,
  setCoinColor,
} from "./redux/slices/chartSymbolSlice";

const HISTORY_STORAGE_KEY = "chartsHistory";

const MainContainer = styled.div`
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  @media (min-width: 1201px) {
    width: 600px;
  }

  @media (max-width: 1200px) {
    width: 550px;
  }
  @media (max-width: 650px) {
    max-width: 400px;
  }
`;
const SearchIcon = styled(AiOutlineSearch)`
  margin: auto 0.5rem auto 0;
`;

const SearchBarInputContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const SearchInput = styled.input`
  border: 1px var(--input-style) var(--background-color) !important;
  margin: unset;
  padding: 0;
`;
const Container = styled.div`
  padding: 1rem 2rem;
  position: relative;
  border-radius: 30px;
  display: flex;
  border: 1px solid var(--code-bg-color);
  margin: 0.5rem;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: 1201px) {
    width: 600px;
  }

  @media (max-width: 1200px) {
    width: 550px;
  }
  @media (max-width: 650px) {
    ${"" /* max-width: 400px; */}
    padding: .2rem .4rem;
  }
`;

const CoinIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 0.7rem;
`;

const DropdownContainer = styled(Container)`
  padding: 0.25rem 2rem 0.25rem 2rem;
  background-color: var(--background-color);
  max-height: 300px;
  width: 100%;
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;

  @media (max-width: 650px) {
    padding: 0.25rem 0.8rem;
  }
`;

const DropdownCrypto = styled.div`
  flex-direction: column;
  position: relative;
  left: 0;
  top: 100%;
  z-index: 2;
  max-height: 250px;
  overflow-y: auto;
  background-color: var(--background-color);
  border-top: 2px #333339 solid;
  span {
    display: flex;
    padding: 1rem 2rem;
    width: 100%;
    align-items: center;
    margin: auto auto auto 0;
    justify-content: flex-start;
    &:hover {
      background-color: #3f3f44;
    }
    @media (max-width: 650px) {
      padding: 1rem 0.5rem;
    }
  }
`;
const CryptoGraphSearch = ({ isDropdownOpen, setIsDropdownOpen }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const chartSymbol = useSelector(
    (state) => state.cryptocurrencyData.chartSymbol
  );
  const coinName = useSelector((state) => state.cryptocurrencyData.coinName);
  const coinColor = useSelector((state) => state.cryptocurrencyData.coinColor);
  const [searchData, setSearchData] = useState({ searchQuery: "" });
  const [selectedOption, setSelectedOption] = useState(
    `${chartSymbol}-${coinName}`
  );

  const handleClick = () => {
    if (isDropdownOpen) {
      setIsDropdownOpen(false);
    } else {
      setIsDropdownOpen(true);
    }
  };
  const handleDispatch = (crypto) => {
    setIsDropdownOpen(false);
    dispatch(setChartSymbol(crypto.chartSymbol));
    dispatch(setCoinName(crypto.coinName));
    dispatch(setCoinColor(crypto.coinColor));
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const filteredOptions = cryptocurrencies.filter((x) =>
        `${x.chartSymbol}-${x.coinName}`
          .toLowerCase()
          .includes(searchData.searchQuery.toLowerCase())
      );
      if (filteredOptions.length > 0) {
        handleDispatch(filteredOptions[0]);
        setSelectedOption(filteredOptions[0]);
      }
    }
  };
  return (
    <>
      {" "}
      <MainContainer styled={{ position: "relative" }}>
        <DropdownContainer>
          <form
            onClick={() => setIsDropdownOpen(true)}
            onSubmit={async (event) => {
              event.preventDefault();
              const query = searchData.searchQuery;
              console.log("searched for:", query);

              // saving to local storage
              const history =
                JSON.parse(localStorage.getItem(HISTORY_STORAGE_KEY)) || [];
              if (!history.find((x) => x === query)) {
                localStorage.setItem(
                  HISTORY_STORAGE_KEY,
                  JSON.stringify([query, ...history])
                );
              }

              // navigate(`/charts/${query}`);
            }}
          >
            <SearchBarInputContainer>
              <SearchIcon />
              <SearchInput
                type="text"
                placeholder=""
                minLength="1"
                spellCheck="false"
                onKeyPress={handleKeyPress}
                value={
                  isDropdownOpen
                    ? searchData.searchQuery
                    : `${chartSymbol} - ${coinName}`
                }
                onChange={(e) =>
                  setSearchData({ ...searchData, searchQuery: e.target.value })
                }
              />{" "}
            </SearchBarInputContainer>
          </form>
          <DropdownCrypto style={{ display: isDropdownOpen ? "flex" : "none" }}>
            {cryptocurrencies.map((x) => (
              <>
                {`${x.chartSymbol} - ${x.coinName}`
                  .toLowerCase()
                  .includes(searchData.searchQuery.toLowerCase()) ? (
                  <span key={x.id} onClick={() => handleDispatch(x)}>
                    <CoinIcon
                      src={`https://assets.coincap.io/assets/icons/${x.chartSymbol.toLowerCase()}@2x.png`}
                    />
                    {x.chartSymbol} - {x.coinName}
                  </span>
                ) : (
                  <></>
                )}
              </>
            ))}
          </DropdownCrypto>
        </DropdownContainer>{" "}
      </MainContainer>
    </>
  );
};

{
}

export default CryptoGraphSearch;
