import styled from "styled-components";

export const NewsIcons = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  padding: 0.3rem 0.5rem 0.2rem 0.5rem;
  height: 65px;

  @media (max-width: 991px) {
  }
  @media (max-width: 767px) {
    padding: 0.3rem 0.3rem 0.2rem 0.3rem;
  }
  @media (max-width: 650px) {
    padding: 0.2rem;

    margin-bottom: 0.1rem;
  }

  @media (max-width: 350px) {
    padding-right: 0rem;
  }
`;
export const NewsHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  font-weight: 200;
  height: 85px;
  padding-bottom: 0.1rem;
  margin-top: 0.2rem;
  margin-right: 0.4rem;
  margin-left: 0.4rem;
  overflow: hidden;

  a {
    cursor: pointer;
    color: #e8e9ed;
    height: fit-content;
    text-align: left;
  }
  @media (max-width: 650px) {
    overflow: visible;
  }
`;
export const Image = styled.img`
  display: block;
  justify-content: center;
  height: 200px;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  &:hover {
    background-color: transparent !important;
  }
  @media (max-width: 650px) {
    height: 250px;

    padding: 0;
    margin-bottom: 2rem;
    margin-bottom: 0 !important;
  }
`;
export const TopStoryImageContainer = styled.a`
  width: 100%;
`;
export const LoadingWrapper = styled.div`
  align-items: start;
  justify-content: center;
  width: 100%;
  display: flex;
`;

export const BtcBalPara = styled.p`
  font-size: 25px;
  color: green;
  margin-bottom: 0.2rem;
`;

export const UsdBalPara = styled.p`
  font-size: 20px;
  color: green;
  margin-bottom: 0.2rem;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 1rem auto auto auto;
  width: auto;
  padding-right: 10px;
  padding-left: 10px;

  @media (min-width: 1201px) {
    width: 1180px;
  }
  @media (max-width: 1200px) {
    max-width: 1000px;
  }
  @media (max-width: 991px) {
    max-width: 950px;
    padding-right: 8px;
    padding-left: 8px;
  }

  @media (max-width: 767px) {
    max-width: 500px;
  }
  @media (max-width: 650px) {
    width: 99%;
    padding-right: 0.1rem;
    padding-left: 0.1rem;
  }
`;
