import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  timeRange: "1D",
  apiPoints: "24",
  graphtype: "histohour",
  timeFrame: "Today",
};

//dont know what to name these yet
const timeCustomSlice = createSlice({
  name: "chartTimeCustomization",
  initialState,
  reducers: {
    //object reducers where everything that touches state and actions
    //and reducers that modify it
    dayChartState: (state) => {
      state.timeRange = "1D";
      state.apiPoints = "24";
      state.graphtype = "histohour";
      state.timeFrame = "Today";
    },

    weekChartState: (state) => {
      state.timeRange = "1W";
      state.apiPoints = "168";
      state.graphtype = "histohour";
      state.timeFrame = "Past Week";
    },

    monthChartState: (state) => {
      state.timeRange = "1M";
      state.apiPoints = "720";
      state.graphtype = "histohour";
      state.timeFrame = "Past Month";
    },
    // incrementByAmount: (state, action) => {
    //2nd param- action , which u can give it a payload,
    //payload allows u to pass any value and icrement it
    //by that
    //   state.count += action.payload;
    // },
  },
});

//Action creators are generated for each case reducer function.
export const { dayChartState, weekChartState, monthChartState } =
  timeCustomSlice.actions;
export default timeCustomSlice.reducer;
