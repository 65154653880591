import { useState, useEffect } from "react";
import styled from "styled-components";

import { Row, Col } from "react-flexbox-grid";
import LoadingBar from "./LoadingBar";
import FeaturedNews from "./FeaturedNews";
import TopStories from "./TopStories";
import ShareButton from "./ShareButton";
import ScoreContainer from "./ScoreContainer";

import { API_URL } from "./config";
import axios from "axios";

const Disclaimer = styled.div`
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 10px;
  /* max-width: 800px; */
  border-bottom: thick double #32a1ce;
  border-radius: 5px;
  /* border: 2px solid #32a1ce; */
`;

const Story = styled.div`
  /* display: flex; */
  /* flex-wrap: wrap; */
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 10px;
  max-width: 800px;
  border: thin double #32a1ce;
  border-radius: 5px;
  border: 2px solid #32a1ce;
`;

const StoryTitle = styled.h2`
  margin-bottom: 0px;
  border-bottom: thin dotted #32a1ce;
`;

const PostedAt = styled.p`
  margin-bottom: 8px;
  font-size: 9pt;
`;

const Wrapper = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    padding: 0.9rem;
  }
`;

const NewsBeta = () => {
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [aiNews, setAiNews] = useState([]);

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(22);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const getAiNews = async () => {
      try {
        const response = await axios.get(`${API_URL}/d2-news`);

        setAiNews(response.data.news);
      } catch (error) {
        console.log(error);
      }
    };
    getAiNews();
  }, [page]);

  useEffect(() => {
    document.title = "d2index | News (beta)";
  }, []);

  return (
    <>
      <Disclaimer>
        Note that this is a <strong>beta</strong> product involving the use of
        generative AI and may contain omissions, errors, or other irregularities
        as we iterate on this experience.
      </Disclaimer>
      <Wrapper>
        {aiNews.map((x) => {
          const {
            id,
            imageUrl,
            isPublished,
            // d2index article data
            d2IndexUrl,
            d2IndexTitle,
            d2IndexArticle,
            // original article data
            originalUrl,
            originalTitle,
            originalArticle,
            postedAt,
          } = x;
          const d2ArticleWithTags =
            "<p>" + d2IndexArticle.replace(/\n{2}/g, "&nbsp;</p><p>") + "</p>";
          console.log("story id:", id);
          return (
            <Story key={id}>
              <StoryTitle>{d2IndexTitle}</StoryTitle>
              <PostedAt>
                Posted at{" "}
                {new Date(postedAt).toLocaleString("en-US", {
                  month: "short", // abbreviated month name
                  day: "2-digit", // two-digit day of the month
                  year: "numeric", // full year
                  hour: "2-digit", // two-digit hours (00-23)
                  minute: "2-digit", // two-digit minutes (00-59)
                  second: "2-digit", // two-digit seconds (00-59)
                  hour12: true, // 12-hour clock format
                })}
              </PostedAt>
              {/* <img src={imageUrl} /> */}
              {/* <a href={d2IndexUrl}>{d2IndexUrl}</a> */}
              <p dangerouslySetInnerHTML={{ __html: d2ArticleWithTags }} />
              {/* <p>{d2IndexArticle}</p> */}
            </Story>
          );
        })}
      </Wrapper>
    </>
  );
};

export default NewsBeta;
