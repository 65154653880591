import { Row, Col } from "react-flexbox-grid";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { FiLinkedin } from "react-icons/fi";
import Menu from "./components/Menu";
import Burger from "./components/Burger";
import useOnClickOutside from "./hooks";

const NavigationBar = styled(Row)`
  position: sticky;
  padding: 0.6rem 1rem 0.3rem 1rem;
  margin: auto;
  top: 0;
  background-color: #222225;
  z-index: 100;
  border-bottom: 2px #333339 solid;
  @media (max-width: 768px) {
  }
`;

const Title = styled.h2`
  margin: unset;
  padding: unset;
  line-height: unset;
  @media (max-width: 768px) {
  }
`;
const Subtitle = styled.h5`
  font-size: 0.7rem;
  margin: unset;
  padding: unset;
  @media (max-width: 768px) {
  }
`;

const StyledNav = styled.nav`
  display: flex;
  margin: auto;
  justify-content: flex-end;
  @media (max-width: 991px) {
    display: none;
  }
`;

const Header = () => {
  const [open, setOpen] = useState(false);
  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));
  return (
    <>
      <NavigationBar>
        <Col xs={9} sm={8} md={4} lg={5}>
          <Link to="/">
            <Col>
              <Row>
                {" "}
                <Title>d2index</Title>{" "}
              </Row>
              <Row>
                {" "}
                <Subtitle>Distributed &amp; DeFi Index</Subtitle>{" "}
              </Row>{" "}
            </Col>
          </Link>
        </Col>
        <Col xs={3} sm={4} md={8} lg={7}>
          <StyledNav>
            <li>
              <NavLink to="news">News</NavLink>
            </li>
            <li>
              <NavLink to="watchlist">Watchlist</NavLink>
            </li>
            <li>
              <NavLink to="charts">Charts</NavLink>
            </li>
            <li>
              <NavLink to="btc-explorer">BTC Explorer</NavLink>
            </li>
            <li>
              <NavLink to="btc-live">BTC Live</NavLink>
            </li>
          </StyledNav>

          <div ref={node}>
            {" "}
            <Row end="xs">
              <Burger open={open} setOpen={setOpen} />
              <Menu open={open} setOpen={setOpen} />
            </Row>{" "}
          </div>
        </Col>
      </NavigationBar>
    </>
  );
};

export default Header;
