// Menu.styled.js
import styled from "styled-components";

export const StyledMenu = styled.nav`
  display: none;
  flex-direction: column;
  justify-content: center;
  z-index: 100;

  height: auto;
  text-align: left;
  background-color: #222225;
  padding: 0.8rem;
  position: fixed;
  border-top: 1px solid white;
  right: 0;
  top: 3.25rem;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(200%)")};

  a {
    padding: 1.3rem 0;
    font-weight: bold;
    text-decoration: none;
    transition: color 0.3s linear;

    &:hover {
      color: var(--primary-color);
    }
  }
  @media (max-width: 991px) {
    display: flex;
  }
`;
