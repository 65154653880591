export const cryptocurrencies = [
  {
    id: 1,
    chartSymbol: "BTC",
    coinName: "Bitcoin",
    coinColor: "250,174,50",
  },
  { id: 2, chartSymbol: "ETH", coinName: "Ethereum", coinColor: "78,56,216" },
  { id: 3, chartSymbol: "USDT", coinName: "Tether", coinColor: "38, 161, 123" },
  { id: 4, chartSymbol: "USDC", coinName: "USD Coin", coinColor: "39,117,202" },
  { id: 5, chartSymbol: "XRP", coinName: "XRP", coinColor: "0, 170, 228" },
  { id: 6, chartSymbol: "SOL", coinName: "Solana", coinColor: "3, 225, 255" },
  { id: 7, chartSymbol: "ADA", coinName: "Cardano", coinColor: "26, 117, 187" },
  {
    id: 8,
    chartSymbol: "LTC",
    coinName: "Litecoin",
    coinColor: "153, 153, 153",
  },
];
