import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Row, Col } from "react-flexbox-grid";
import LoadingBar from "./LoadingBar";
import FeaturedNews from "./FeaturedNews";
import TopStories from "./TopStories";
import NewsArticle from "./NewsArticle";
import { AiOutlineSearch } from "react-icons/ai";
import ShareButton from "./ShareButton";
import ScoreContainer from "./ScoreContainer";
import { PageContainer, LoadingWrapper } from "./sharedStyleds";
import SearchBox from "./SearchBox";
import { API_URL } from "./config";
import axios from "axios";

const NewsHeaderSection = styled.div`
  display: flex;
  left: 0;
  @media (max-width: 650px) {
    margin: 1.5rem 0 0 0;
    padding: 0;
  }
`;

const News = () => {
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [newsData, setNewsData] = useState({
    topStories: [],
    featuredStory: {},
  });

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(22);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const getNewsApi = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/news?page=${page}&limit=${limit}`
        );
        const apiNews = response.data;

        if (page == 1) {
          setNewsData((oldNews) => ({
            ...oldNews,
            topStories: [...oldNews.topStories, ...apiNews.topStories],
            featuredStory: apiNews.featuredStory,
          }));
        } else {
          setNewsData((oldNews) => ({
            ...oldNews,
            topStories: [...oldNews.topStories, ...apiNews.topStories],
          }));
        }
      } catch (error) {
        console.log(error);
      }
    };
    getNewsApi();
  }, [page]);

  useEffect(() => {
    // NOTE: every page needs to set a title, even home page
    document.title = "d2index | Distributed & DeFi Index";
  }, []);

  return (
    <div>
      {/* <Col>
        <Link to="/newsarticle">
          {" "}
          <button className="btn btn-ghost">News Article Mockup </button>
        </Link>
      </Col> */}

      {newsData.topStories.length ? (
        <>
          <PageContainer>
            {/* <Col xs={12} sm={12} md={12}>
              <SearchBox />
            </Col> */}
            <Col xs={12} sm={12} md={12} lg={8}>
              <FeaturedNews story={newsData.featuredStory} />
            </Col>

            {newsData.topStories.map((x) => (
              <Col xs={12} sm={12} md={6} lg={4} key={x.d2Id}>
                <TopStories story={x} />
              </Col>
            ))}
            <Col xs={12}>
              <Row center="xs">
                <button
                  className="btn btn-primary"
                  onClick={() => setPage(page + 1)}
                >
                  Load More
                </button>
              </Row>
            </Col>
          </PageContainer>{" "}
        </>
      ) : (
        <LoadingWrapper>
          <NewsHeaderSection>
            <LoadingBar message={`fetching latest news...`} />
          </NewsHeaderSection>
        </LoadingWrapper>
      )}
    </div>
  );
};

export default News;
