import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  BarChart,
  YAxis,
  Bar,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
const darkModeTooltipStyle = {
  background: "#333",
  border: "1px solid #555",
  color: "#fff",
  borderRadius: "5px",
  padding: "10px",
};

const formatTimeToMinutes = (time) => {
  const date = new Date(time);
  return `${date.getHours()}:${date.getMinutes()}`;
};

const groupTransactionsByMinute = (transactions) => {
  return transactions.reduce((acc, transaction) => {
    // Get the minute part of the time as a string
    const minute = formatTimeToMinutes(transaction.time);

    // Check if minute is already a key in the accumulator
    if (!acc[minute]) {
      // If not, initialize it with an empty array
      acc[minute] = [];
    }

    // Push the current transaction to the array of that minute
    acc[minute].push({ ...transaction, min: minute });

    return acc;
  }, {});
};

const TransactionVisualization = ({ transactions }) => {
  // Assuming transactions is an array of objects with the specified shape
  // { time, hash, inputs, out, outputTotalBtc }

  const data = groupTransactionsByMinute(transactions);
  // console.log("!", data);
  const dataArr = [];
  for (const key in data) {
    const btc = data[key].reduce((acc, tx) => {
      return acc + tx.outputTotalBtc;
    }, 0);
    dataArr.push({ min: key, btc });
  }

  // TODO: refactor this
  dataArr.reverse();

  return (
    <div>
      <ResponsiveContainer width="100%" height={200}>
        <BarChart data={dataArr}>
          <defs>
            <linearGradient id="colorUv" x1="1" y1="1" x2="0" y2="0">
              <stop offset="25%" stopColor="#3C1053" stopOpacity={0.5} />
              <stop offset="75%" stopColor="#FF9900" stopOpacity={0.5} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="min" />
          <YAxis dataKey="btc" unit="BTC" />
          <Bar dataKey="btc" fill="url(#colorUv)" isAnimationActive={false} />
        </BarChart>
      </ResponsiveContainer>
      <br />
    </div>
  );
};

export default TransactionVisualization;
