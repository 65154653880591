import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import styled from "styled-components";
import LoadingBar from "./LoadingBar";
import FullTxDetails from "./FullTxDetails";
import { fmtUsd, satsToBtc, truncateHash, btcToString } from "./utils";
import { BtcBalPara, UsdBalPara, LoadingWrapper } from "./sharedStyleds";
import { Link } from "react-router-dom";

const AddressList = styled.ul`
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
`;

const TxLink = styled(Link)`
  color: #003f7b;
`;

const BtcExplorerPage = styled.div`
  margin-top: 1rem;
  width: 100%;
  /* Picked 20px as it seems to keep things aligned with the logo on the top left */
  padding-left: 20px;
  padding-right: 20px;
`;

const TerminalCard = styled.div`
  padding: 0 1rem;
  width: auto;
`;

const Title = styled.h2`
  margin-bottom: 0;
`;

const BalanceHeader = styled.h3`
  margin-bottom: 0.5rem;
`;

const TxDetails = styled.p`
  margin-bottom: 0.2rem;
`;

const getBtcPrice = async () => {
  const response = await fetch(
    "https://api.coinbase.com/v2/prices/spot?currency=USD"
  );
  const json = await response.json();
  return parseFloat(json.data.amount);
};

const getWalletData = async (walletId) => {
  const response = await fetch(
    `https://blockchain.info/multiaddr?active=${walletId}`
  );
  // TODO: check for 404 here
  if (response.status === 200) {
    const json = await response.json();
    console.log("wallet retrieved is", json);
    return { ...json, fetched: true };
  } else {
    console.log("error reponse", response);
    return { error: "No results", wallet: [], fetched: true };
  }
};

const getTxData = async (txHash) => {
  const response = await fetch(`https://blockchain.info/rawtx/${txHash}`);
  // TODO: check for 404 here
  if (response.status === 200) {
    const json = await response.json();
    console.log("tx retrieved is", json);
    return { ...json, fetched: true };
  } else {
    console.log("error reponse", response);
    return { error: "No results", wallet: [], fetched: true };
  }
};

const BtcExplorer = () => {
  // TODO: wallet is 26-35 characters, tx hash is 64 characters
  // we should do better error handling and maybe field level validation
  let { walletId } = useParams();
  console.log("wallerId", walletId);
  const [isTx, setIsTx] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [walletData, setWalletData] = useState({
    addresses: [],
    wallet: {},
    txs: [],
  });
  const [txData, setTxData] = useState({});
  const [btcPrice, setBtcPrice] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    const isTxResult = walletId.length === 64;
    setIsTx(isTxResult);

    // TODO: use helmet for this
    document.title = `d2index | BTC Explorer ${
      isTxResult ? "transaction" : "wallet"
    } lookup`;

    (async () => {
      if (isTxResult) {
        setTxData(await getTxData(walletId));
      } else {
        setWalletData(await getWalletData(walletId));
      }
      setBtcPrice(await getBtcPrice(walletId));
      // TODO: use http://blockchain.info/latestblock to calc # confirmations
      setIsLoading(false);
    })();
  }, [walletId]);

  const {
    addresses,
    wallet: { final_balance },
    txs,
  } = walletData;
  const btcBalanceNumber = satsToBtc(final_balance);
  const btcBalancePretty = btcToString(btcBalanceNumber);
  const balanceUsdPretty = fmtUsd(btcPrice * btcBalanceNumber);
  const btcPricePretty = fmtUsd(btcPrice);

  return (
    <BtcExplorerPage>
      <Title>Bitcoin {isTx ? "Transaction" : "Wallet"} Lookup:</Title>
      {/* TODO: search bar to the right of Title header */}

      {isLoading ? (
        <LoadingWrapper>
          <LoadingBar />
        </LoadingWrapper>
      ) : isTx ? (
        <>
          {txData.error ? (
            <span>{txData.error}</span>
          ) : (
            <FullTxDetails txData={txData} btcPrice={btcPrice} />
          )}
        </>
      ) : (
        <>
          {walletData.error ? (
            <span>{walletData.error}</span>
          ) : (
            <>
              <AddressList>
                {addresses.map((x) => (
                  <li key={x.address}>{x.address}</li>
                ))}
              </AddressList>

              <BalanceHeader>Balance:</BalanceHeader>
              <BtcBalPara>{btcBalancePretty} BTC</BtcBalPara>
              <UsdBalPara>${balanceUsdPretty}</UsdBalPara>
              <p>Spot price: ${btcPricePretty}/USD</p>

              {/* <Col xs={12} sm={12} md={7} lg={7}> */}
              <div className="terminal-timeline">
                {txs.map((x) => {
                  const fmtBtc = Math.abs(satsToBtc(x.result));
                  const fmtBtcPretty = btcToString(fmtBtc);
                  const isOutgoing = x.result < 0;
                  return (
                    // TODO: make this own component
                    <TerminalCard key={x.hash}>
                      <div className="terminal-card">
                        <header>
                          TX:{" "}
                          <TxLink to={`/btc-explorer/${x.hash}`}>
                            {truncateHash(x.hash)}
                          </TxLink>
                        </header>
                        <div>
                          <TxDetails
                            style={{ color: isOutgoing ? "red" : "green" }}
                          >
                            {fmtBtcPretty} BTC (${fmtUsd(btcPrice * fmtBtc)}){" "}
                            {isOutgoing ? "sent" : "received"}
                          </TxDetails>
                          <TxDetails>
                            {new Date(x.time * 1000).toLocaleString()}
                          </TxDetails>
                        </div>
                      </div>
                    </TerminalCard>
                  );
                })}
              </div>
            </>
          )}
        </>
      )}
    </BtcExplorerPage>
  );
};

export default BtcExplorer;
