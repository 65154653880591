import React from "react";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { Row, Col } from "react-flexbox-grid";
import fillerimage1 from "./fillerimages/fillerimage1.jpg";
import ShareButton from "./ShareButton";
import { API_URL } from "./config";
import axios from "axios";

const FeaturedNewsItem = styled.div`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin: 0.4rem;
  border: 2px solid rgb(247, 147, 26);
  box-shadow: 5px 15px 20px 0 rgba(247, 147, 26, 0.35),
    10px 18px 45px 0 rgba(247, 147, 26, 0.35);
  @media (max-width: 991px) {
    margin: 1rem 0.4rem;
  }
  @media (max-width: 650px) {
  }
`;

const FeaturedHeader = styled.h2`
  position: absolute;
  border-radius: 5px;

  color: rgb(247, 147, 26);
  margin: 0;
  width: auto;
  padding: 0.4rem;

  background-color: rgba(34, 34, 37, 0.85);
  @media (max-width: 991px) {
    font-size: 1.1em;
  }
  @media (max-width: 650px) {
    position: unset;
    font-size: 1rem;
  }
`;

const FeaturedImageContainer = styled.a`
  position: relative;
`;

const Image = styled.img``;

const FeaturedDatePosted = styled.div`
  width: 100%;
  padding: 0.4rem;
  margin-bottom: 0;
  position: absolute;
  bottom: 0;
  background-color: rgba(34, 34, 37, 0.85);
`;

const NewsIcons__Featured = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  padding: 0;
  margin-top: auto;
`;

const FeaturedNews = ({ story }) => {
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const domain = new URL(story.storyUrl).hostname;

  return (
    <FeaturedNewsItem key={story.d2Id}>
      <FeaturedImageContainer
        href={String(story.storyUrl)}
        target="_blank"
        rel="noreferrer"
      >
        <FeaturedHeader>
          {story.title} ({domain})
        </FeaturedHeader>
        {story.imageUrl ? (
          <Image src={story.imageUrl} />
        ) : (
          <Image src={fillerimage1} />
        )}
        <FeaturedDatePosted>
          {" "}
          posted {new Date(story.postedAt).toLocaleString()}
        </FeaturedDatePosted>{" "}
      </FeaturedImageContainer>
      <NewsIcons__Featured>
        {/* TODO: disabling until favoriting is implemented */}
        {/* <ScoreContainer
          story={newsData.featuredStory}
          id={newsData.featuredStory.d2Id}
          score={newsData.featuredStory.d2Score}
          style={{ marginTop: 3 }}
        /> */}

        <ShareButton story={story} />
      </NewsIcons__Featured>
    </FeaturedNewsItem>
  );
};

export default FeaturedNews;
