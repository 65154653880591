import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "../config";

export const d2indexApi = createApi({
  reducerPath: "d2indexApi", //reducerPath-->tells u what its for
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getNews: builder.query({
      query: () => "/news",
    }),
  }),
});

export const { useGetNewsQuery } = d2indexApi;
