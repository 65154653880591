import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const TransactionLineChart = ({ transactions }) => {
  const formatTimeToMinutes = (time) => {
    const date = new Date(time);
    return `${date.getHours()}:${date.getMinutes()}`;
  };

  // Count the number of transactions per minute
  const transactionsPerMinute = transactions.reduce((acc, transaction) => {
    const minute = formatTimeToMinutes(transaction.time);

    if (!acc[minute]) {
      acc[minute] = 1;
    } else {
      acc[minute]++;
    }

    return acc;
  }, {});

  // Convert the transactions per minute object to an array
  const data = Object.keys(transactionsPerMinute).map((minute) => ({
    minute,
    transactions: transactionsPerMinute[minute],
  }));

  // TODO: refactor this
  data.reverse();

  return (
    <ResponsiveContainer width="100%" height={200}>
      <LineChart
        // width={800}
        // height={400}
        data={data}
        // margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="minute" />
        <YAxis unit="x" />
        {/* <Tooltip /> */}
        {/* <Legend /> */}
        <Line
          type="monotone"
          dataKey="transactions"
          stroke="#8884d8"
          // activeDot={{ r: 8 }}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default TransactionLineChart;
