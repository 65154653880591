/**
 * Common utilities used throughout the app
 */
export const satsToBtc = (sats) => sats / 100000000;

export const btcToString = (x) =>
  x.toLocaleString(undefined, {
    minimumFractionDigits: 8,
    maximumFractionDigits: 8,
  });

export const fmtUsd = (x) =>
  x
    ? x.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : 0;

export const truncateHash = (s) =>
  `${s.substring(0, 6)}...${s.substring(s.length - 6)}`;

export const fmtLargeNum = (x) => {
  if (!x) {
    return 0;
  }

  if (x >= 1000000000) {
    // where is the dollar sign?
    return (x / 1000000000).toFixed(4).replace(/\.0$/, "") + " B";
  }
  if (x >= 1000000) {
    return "$" + (x / 1000000).toFixed(1).replace(/\.0$/, "") + " M";
  }
  if (x >= 1000) {
    return (x / 1000).toFixed(1).replace(/\.0$/, "") + " K";
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const fmtChange = (x) => {
  if (!x) {
    return 0;
  }

  return x.toFixed(2).replace(/\.?0*$/, " %");
};
