// Load any app-wide CSS here
import "terminal.css";
import "./App.css";

import Charts from "./Charts";
import Layout from "./Layout";
import News from "./News";
import NewsBeta from "./NewsBeta";
import SearchPage from "./SearchPage";

import Watchlist from "./Watchlist";

import Profile from "./Profile";
import BtcExplorer from "./BtcExplorer";
import BtcSearch from "./BtcSearch";
import BtcLive from "./BtcLive";
import NewsArticle from "./NewsArticle";

// import NFTGallery from "./NFTGallery";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Auth0ProviderWithHistory from "./Auth0Provider";
import CryptoGraphSearch from "./CryptoGraphSearch";

function App() {
  return (
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<News />} />
            <Route path="news" element={<NewsBeta />} />
            <Route path="search/:searchQuery" element={<SearchPage />} />

            <Route path="newsarticle" element={<NewsArticle />} />

            <Route path="profile" element={<Profile />} />
            <Route path="watchlist" element={<Watchlist />} />

            <Route path="charts" element={<Charts />} />
            <Route
              path="charts/:searchCharts"
              element={<CryptoGraphSearch />}
            />
            <Route path="btc-explorer" element={<BtcSearch />} />
            <Route path="btc-explorer/:walletId" element={<BtcExplorer />} />
            <Route path="btc-live" element={<BtcLive />} />
          </Route>
        </Routes>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  );
}

export default App;
