import axios from "axios";

export const getWatchlistApi = async (WATCHLIST_API_KEY) => {
  try {
    const response = await axios.get(
      `https://min-api.cryptocompare.com/data/top/totalvolfull?limit=25&tsym=USD&api_key=${WATCHLIST_API_KEY}`
    );
    const apiWatchlist = response.data.Data;

    return apiWatchlist
      .map((object) => ({
        fullName: object.CoinInfo.FullName,
        imagepath: object.CoinInfo.ImageUrl,
        symbol: object.RAW?.USD.FROMSYMBOL,
        price: object.RAW?.USD.PRICE,
        change24h: object.RAW?.USD.CHANGEPCT24HOUR,
        change1h: object.RAW?.USD.CHANGEPCTHOUR,
        marketCap: object.RAW?.USD.MKTCAP,
        volume24h: object.RAW?.USD.VOLUME24HOURTO,
      }))
      .filter((x) => x.symbol);
  } catch (error) {
    console.log(error);
  }
};
