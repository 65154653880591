import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { AiOutlineSearch } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const HISTORY_STORAGE_KEY = "searchQueryHistory";

const SearchIcon = styled(AiOutlineSearch)`
  margin: 0.7em 0.5em;
`;
const Box = styled.form`
  width: 100%;
  margin: 0.4rem;
  padding: 0rem 0.4rem;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid grey;
  input {
    border: none !important;
  }

  @media (max-width: 991px) {
    margin: 1rem 0.4rem;
  }
`;
const SearchBox = () => {
  let navigate = useNavigate();

  const [searchData, setSearchData] = useState({
    searchQuery: "",
  });
  return (
    <Box
      onSubmit={async (event) => {
        event.preventDefault();
        const query = searchData.searchQuery;
        console.log("searched for:", query);

        // saving to local storage
        const history =
          JSON.parse(localStorage.getItem(HISTORY_STORAGE_KEY)) || [];
        if (!history.find((x) => x === query)) {
          localStorage.setItem(
            HISTORY_STORAGE_KEY,
            JSON.stringify([query, ...history])
          );
        }

        navigate(`/search/${query}`);
      }}
    >
      <SearchIcon />
      <input
        type="text"
        placeholder="Search d2index"
        required=" "
        minLength="1"
        onChange={(e) => setSearchData({ searchQuery: e.target.value })}
        value={searchData.searchQuery}
      />
      <button className="btn btn-default btn-ghost">SEARCH</button>
    </Box>
  );
};

export default SearchBox;
