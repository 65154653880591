import React from "react";
import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-flexbox-grid";
import LoadingBar from "./LoadingBar";
import { fmtUsd, fmtLargeNum, fmtChange } from "./utils";
import { getWatchlistApi } from "./getWatchlistApi";

const WATCHLIST_API_KEY =
  "768dd429e59bf8cdee3b94fead85105f6cd7556ce5362991345fc59a9e80103f";

const Page = styled.div`
  width: auto;
  margin: 2rem;
  box-sizing: border-box;

  @media (max-width: 767px) {
    margin: 0.5rem;
  }
`;
const WatchlistTable = styled.table`
  width: 950px;
  margin: auto;
  td,
  th {
    border: 1px solid var(--code-bg-color);
  }

  @media (max-width: 1200px) {
  }
  @media (max-width: 991px) {
    width: 750px;
  }

  @media (max-width: 767px) {
  }
  @media (max-width: 650px) {
  }
`;

const CoinHeaders = styled.div`
  margin-bottom: 0.1rem !important;
  padding-left: 10px;

  h3,
  h5 {
    margin-bottom: 0.1rem !important;
  }

  h5 {
    font-weight: 100;
  }
`;

const Headers = styled.tr`
  font-size: 1.2rem;
  ${"" /* color: var(--secondary-color); */}
`;

const Image = styled.img`
  width: 40px;
  height: 40px;
`;

const TableNumbers = styled.td`
  vertical-align: middle;
  font-weight: 100 !important;
  color: var(--secondary-color);
`;

const Watchlist = () => {
  // for horizontal scroll to a div on smaller screens
  const divToScrollTo = useRef(null);
  const parentElement = useRef(null);

  const [cryptoData, setCryptoData] = useState([]);
  // this is kind of a hack to update intervalId in state for the effect cleanup hook...
  const [loop, setLoop] = useState({});

  // this updates state
  const doFetch = async () => {
    setCryptoData(await getWatchlistApi(WATCHLIST_API_KEY));
  };

  useEffect(() => {
    document.title = "d2index | Watchlist";
    doFetch();

    console.log("setInterval");
    loop.intervalId = setInterval(() => {
      console.log("~ 10-second interval API call hit ~");
      doFetch();
    }, 10 * 1000);
    console.log("loop.intervalId", loop.intervalId);

    return () => {
      console.log("chart clean up", loop);
      console.log("cryptoData, typeOF", cryptoData, typeof cryptoData);

      clearInterval(loop.intervalId);
    };
  }, []);

  useEffect(() => {
    parentElement.current.scrollTo({
      left: divToScrollTo.current.offsetLeft,
      behavior: "smooth",
    });
  }, []);

  return (
    <Page ref={parentElement} style={{ overflowX: "scroll" }}>
      <WatchlistTable ref={divToScrollTo}>
        <thead>
          <Headers>
            <th>Coin</th>
            <th>Price</th>
            <th>Volume 24h </th>
            {/* <th>All-time high </th> */}
            <th>Market Cap</th>
            <th>1h </th>
            <th>24h </th>
          </Headers>
        </thead>
        <tbody style={{ margin: 2, marginBottom: 0 }}>
          {cryptoData.map(
            ({
              symbol,
              price,
              fullName,
              volume24h,
              marketCap,
              change1h,
              change24h,
              imagepath,
            }) => {
              const is1hNegative = change1h < 0;
              const is24hNegative = change24h < 0;

              const imageUrl = `https://www.cryptocompare.com${imagepath}`;
              return (
                <tr key={symbol}>
                  <td>
                    {" "}
                    <Row style={{ marginLeft: 2 }}>
                      <Image src={imageUrl} />
                      <Col>
                        <CoinHeaders>
                          <h3> {symbol}</h3>
                          <h5> {fullName}</h5>
                        </CoinHeaders>
                      </Col>
                    </Row>
                  </td>
                  <TableNumbers>${fmtUsd(price)}</TableNumbers>
                  <TableNumbers>{fmtLargeNum(volume24h)}</TableNumbers>
                  <TableNumbers>{fmtLargeNum(marketCap)}</TableNumbers>
                  <TableNumbers
                    style={{ color: is1hNegative ? "red" : "green" }}
                  >
                    {fmtChange(change1h)}
                  </TableNumbers>
                  <TableNumbers
                    style={{ color: is24hNegative ? "red" : "green" }}
                  >
                    {fmtChange(change24h)}
                  </TableNumbers>
                </tr>
              );
            }
          )}
        </tbody>
      </WatchlistTable>
    </Page>
  );
};

export default Watchlist;
