import styled from "styled-components";

const Wrapper = styled.footer`
  text-align: center;
`;

const Divider = styled.hr`
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

const VersionInfo = styled.p`
  font-size: 12px;
`;

const Footer = () => {
  const { createdAt, releaseVersion } = window.appMetadata;
  const fixedVersion = parseInt(releaseVersion.substring(1)) + 1;
  const formattedDate = new Date(createdAt).toLocaleString();
  return (
    <Wrapper>
      <Divider />
      <VersionInfo>
        v{fixedVersion} | deployed at {formattedDate}
      </VersionInfo>
    </Wrapper>
  );
};

export default Footer;
