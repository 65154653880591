import React from "react";
import { BsFileArrowUp } from "react-icons/bs";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { API_URL } from "./config";

const Container = styled.div`
  display: flex;
  width: auto;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2.7rem;
  padding: 0.7rem;

  &:hover {
    ${"" /* background-color: #333339; */}
  }
  @media (max-width: 991px) {
    width: fit-content;
  }
  @media (max-width: 650px) {
    z-index: 5;
    ${"" /* padding: 0.7rem; */}
  }
`;

const ArrowUp = styled(BsFileArrowUp)`
  background-color: transparent;
  font-weight: 600;
  text-shadow: 5px 5px 0 #e8e9ed;
  font-size: 1.5rem;

  margin-right: 0.3rem;

  &:hover {
    color: #ffb000;
  }
  @media (max-width: 650px) {
    padding: 0;
  }
`;

const Score = styled.div`
  font-size: 0.9rem;
  margin-bottom: 0;

  @media (max-width: 650px) {
  }
`;
const ScoreContainer = ({ score, id, story }) => {
  const [upVoteClicked, setUpVoteClicked] = useState(false);

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const apiGet = () => {
    fetch(`${API_URL}/news/${id}/upvote`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(story),
    }).then(() => console.log("up vote added", story));
  };
  // console.log(typeof story.score);
  const handleUpVote = () => {
    apiGet();
    if (upVoteClicked) {
      setUpVoteClicked(false);
    } else {
      setUpVoteClicked(true);
    }
  };

  return (
    <>
      <Container
        onClick={() => handleUpVote()}
        style={{
          color: upVoteClicked ? "#ffb000" : "#e8e9ed",
        }}
      >
        <ArrowUp
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          style={{
            // border:
            //   upVoteClicked || isHovering
            //     ? "#ffb000 solid 1px"
            //     : "#e8e9ed solid 1px",
            color: upVoteClicked || isHovering ? "#ffb000" : "#e8e9ed",
          }}
        />
        <Score>{upVoteClicked ? story.d2Score + 1 : story.d2Score}</Score>
      </Container>
    </>
  );
};

export default ScoreContainer;
