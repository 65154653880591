import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chartSymbol: "BTC",
  coinName: "Bitcoin",
  coinColor: "250,174,50",
};

const cryptocurrencyDataSlice = createSlice({
  name: "cryptocurrencyData",
  initialState,
  reducers: {
    setChartSymbol: (state, action) => {
      state.chartSymbol = action.payload;
    },

    setCoinName: (state, action) => {
      state.coinName = action.payload;
    },

    setCoinColor: (state, action) => {
      state.coinColor = action.payload;
    },
  },
});

//Action creators are generated for each case reducer function.

export const { setChartSymbol, setCoinName, setCoinColor } =
  cryptocurrencyDataSlice.actions;
export default cryptocurrencyDataSlice.reducer;
