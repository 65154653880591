import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CHART_API_KEY } from "../config";

// const cryptoApiHeaders = {
//   "crypto-host": "https://min-api.cryptocompare.com",
//   "crypto-key":
//     "c297fbb1103968336c0bf54d6e0958244a501bef2f298bfc84e53e11e7f6ab06",
// };

const baseUrl = "https://min-api.cryptocompare.com";

// const createRequest = (url) => ({ url, headers: cryptoApiHeaders });

export const cryptoCompareApi = createApi({
  reducerPath: "cryptoCompareApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://min-api.cryptocompare.com/data/v2/",
  }),
  endpoints: (builder) => ({
    getCryptoData: builder.query({
      query: ({ graphtype, symbol, apiPoints }) =>
        `${graphtype}?fsym=${symbol}&tsym=USD&limit=${apiPoints}&api_key=${CHART_API_KEY}`,
    }),
  }),
});

export const { useGetCryptoDataQuery } = cryptoCompareApi;
