import React from "react";
import { Row, Col } from "react-flexbox-grid";
import fillerimage1 from "./fillerimages/fillerimage1.jpg";
import ShareButton from "./ShareButton";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingBar from "./LoadingBar";
import SearchBox from "./SearchBox";
import styled from "styled-components";

import {
  PageContainer,
  LoadingWrapper,
  TopStoryImageContainer,
  Image,
  ShareIcon,
} from "./sharedStyleds";
import { useGetNewsQuery } from "./services/d2indexApi";

const MyCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

const StoryText = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 0.2rem 0.4rem;
  justify-content: space-between;
  padding: 0.3rem 1rem 0.2rem 1rem;
  @media (max-width: 767px) {
    width: 100%;
    margin: 0.1rem;
    padding: 0.2rem 0.5rem;
  }
`;

const StoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: 2px #333339 solid;
  margin: 1.2rem 0;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const SearchImage = styled(Image)`
  border-radius: 0px;
`;
const SearchPage = () => {
  const { data, error, isLoading } = useGetNewsQuery();
  let { searchQuery } = useParams();
  //TODO: fix to also include the featuredStory if queried

  return (
    <PageContainer>
      {!isLoading ? (
        <>
          <Col xs={12} sm={12} md={12}>
            <SearchBox />

            <div>
              <Col xs={12} sm={12} md={12} lg={12}>
                {" "}
                <h4>Showing results for: {searchQuery}</h4> <br></br>
                {data.topStories.map((x) => (
                  <>
                    {x.title
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()) ? (
                      <>
                        <StoryContainer>
                          <MyCol>
                            <TopStoryImageContainer
                              href={String(x.storyUrl)}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {x.imageUrl ? (
                                <SearchImage src={x.imageUrl} />
                              ) : (
                                <SearchImage src={fillerimage1} />
                              )}
                            </TopStoryImageContainer>{" "}
                          </MyCol>
                          <StoryText>
                            {" "}
                            <div>
                              <h3>
                                <a
                                  href={String(x.storyUrl)}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {x.title}
                                  {"  "}
                                  <span style={{ color: "grey" }}>
                                    ({new URL(x.storyUrl).hostname}){" "}
                                  </span>
                                </a>{" "}
                              </h3>
                              <br /> posted{" "}
                              {new Date(x.postedAt).toLocaleString()}{" "}
                            </div>
                            <ShareButton story={x} />
                          </StoryText>
                        </StoryContainer>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                ))}
              </Col>
            </div>
          </Col>
        </>
      ) : (
        <>
          <LoadingWrapper>
            <LoadingBar />
          </LoadingWrapper>
        </>
      )}
    </PageContainer>
  );
};

export default SearchPage;
