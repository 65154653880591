import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Row, Col } from "react-flexbox-grid";
import LoadingBar from "./LoadingBar";
import { Helmet } from "react-helmet";
import { fmtUsd, satsToBtc, truncateHash, btcToString } from "./utils";
import { Link } from "react-router-dom";
import TransactionChart from "./TransactionChart";
import TransactionLineChart from "./TransactionLineChart";

const BtcLivePage = styled.div`
  margin-top: 1rem;
  /* Picked 20px as it seems to keep things aligned with the logo on the top left */
  padding-left: 20px;
  padding-right: 20px;
`;

const Header = styled.h2`
  margin-bottom: 2px;
`;

const CompactP = styled.p`
  margin-bottom: 0;
`;

const StatsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StatCard = styled.div`
  min-width: 150px;
`;

const ConnectStatus = styled.span`
  background-color: #014401;
  padding: 5px;
  color: #0eed0e;
`;

const TxTable = styled.table`
  font-size: 12px;
`;

const PauseButton = styled.button`
  padding: 0 0.2rem 0 0.2rem;
`;

const DesktopTh = styled.th`
  @media (max-width: 700px) {
    display: none;
  }
`;

const DesktopTd = styled.td`
  @media (max-width: 700px) {
    display: none;
  }
`;

const BtcLive = () => {
  // Docs: https://www.blockchain.com/explorer/api/api_websocket
  const webSocket = useRef(null);
  const [transactions, setTransactions] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [totalBtc, setTotalBtc] = useState(0);

  useEffect(() => {
    // document.title = "d2index | BTC Live";

    // connect when component mounts
    console.log("Initial call to startSocket");
    startSocket();

    // disconnect when component is unmounted
    return stopSocket;
  }, []);

  const startSocket = () => {
    webSocket.current = new WebSocket("wss://ws.blockchain.info/inv");

    webSocket.current.onopen = () => {
      console.log("subscribing...");
      setStartDate(new Date());

      const unconfirmedSub = {
        op: "unconfirmed_sub",
      };
      const blocksSub = {
        op: "blocks_sub",
      };

      webSocket.current.send(JSON.stringify(unconfirmedSub));
      webSocket.current.send(JSON.stringify(blocksSub));
    };

    // should newest items go first in the array?
    webSocket.current.onmessage = (evt) => {
      const message = JSON.parse(evt.data);
      if (message.op === "utx") {
        // console.log("Unconfirmed TX:", message.x);
        const outputTotalBtc = satsToBtc(
          message.x.out.reduce((prev, cur) => prev + cur.value, 0)
        );
        const fixed = {
          ...message.x,
          time: new Date(message.x.time * 1000),
          outputTotalBtc,
        };
        setTransactions((prevArray) => {
          const newArr = [fixed, ...prevArray];
          if (newArr.length > 100000) newArr.pop();
          // console.log(`-> now storing ${newArr.length} transactions ->`);
          return newArr;
        });
        setTotalBtc((prevTotal) => prevTotal + outputTotalBtc);
      } else if (message.op === "block") {
        console.log("New block:", message.x);
        setBlocks((prevArray) => [message.x, ...prevArray]);
      } else {
        console.log("Unknown msg:", message);
      }
    };

    webSocket.onerror = function (err) {
      console.error(err);
    };

    webSocket.current.onclose = () => {
      console.log("disconnected");
      setStartDate(null);
      console.log("Socket is closed. Reconnect will be attempted in 1 second.");
      setTimeout(function () {
        startSocket();
      }, 1000);
    };
  };

  const stopSocket = () => webSocket.current.close();

  return (
    <BtcLivePage>
      <Helmet>
        <title>
          d2index | Bitcoin Live - watch global transactions in real time
        </title>
        <meta
          property="og:title"
          content="Bitcoin Live - watch global transactions in real time"
        />
        <meta
          property="og:description"
          content="A live feed of global, unconfirmed Bitcoin transactions as they're submitted."
        />
        <meta property="og:site_name" content="d2index" />
      </Helmet>
      <Header>
        Bitcoin Live {startDate && <ConnectStatus>connected</ConnectStatus>}
      </Header>
      <p>
        A live feed of global, unconfirmed Bitcoin transactions as they're
        submitted.
      </p>
      <TransactionChart transactions={transactions} />
      <TransactionLineChart transactions={transactions} />
      <StatsContainer>
        {/* <StatCard>
          {startDate ? (
            <button className="btn btn-error btn-ghost" onClick={stopSocket}>
              Pause
            </button>
          ) : (
            <button className="btn btn-primary btn-ghost" onClick={startSocket}>
              Play
            </button>
          )}
        </StatCard> */}
        <StatCard>
          <CompactP>New TXs:</CompactP>
          <CompactP>{transactions.length}</CompactP>
        </StatCard>
        <StatCard>
          <CompactP>BTC sent:</CompactP>
          <CompactP>{btcToString(totalBtc)}</CompactP>
        </StatCard>
        <StatCard>
          <CompactP>New blocks:</CompactP>
          <CompactP>{blocks.length}</CompactP>
        </StatCard>
      </StatsContainer>

      <TxTable>
        <caption>Transactions (last 100 shown)</caption>
        <thead>
          <tr>
            <DesktopTh>Time</DesktopTh>
            <th>Hash</th>
            <th>In</th>
            <th>Out</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {/* See https://en.bitcoin.it/wiki/Transaction */}
          {transactions
            .slice(0, 100)
            .map(({ time, hash, inputs, out, outputTotalBtc }) => (
              <tr key={hash}>
                <DesktopTd>
                  {time.toLocaleString("en-US", {
                    month: "short", // abbreviated month name
                    day: "2-digit", // two-digit day of the month
                    year: "numeric", // full year
                    hour: "2-digit", // two-digit hours (00-23)
                    minute: "2-digit", // two-digit minutes (00-59)
                    second: "2-digit", // two-digit seconds (00-59)
                    hour12: true, // 12-hour clock format
                  })}
                </DesktopTd>
                <td>
                  <Link to={`/btc-explorer/${hash}`}>{truncateHash(hash)}</Link>
                </td>
                <td>{inputs.length}</td>
                <td>{out.length}</td>
                <td>{btcToString(outputTotalBtc)} BTC</td>
              </tr>
            ))}
        </tbody>
      </TxTable>
    </BtcLivePage>
  );
};

export default BtcLive;
