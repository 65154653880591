// Menu.js
import React from "react";
import { bool } from "prop-types";
import { StyledMenu } from "./Menu.styled";
import { NavLink } from "react-router-dom";

const Menu = ({ open, setOpen }) => {
  return (
    <StyledMenu open={open} onClick={() => setOpen(!open)}>
      <NavLink to="news">News</NavLink>

      <NavLink to="watchlist">Watchlist</NavLink>

      <NavLink to="charts">Charts</NavLink>

      <NavLink to="btc-explorer">BTC Explorer</NavLink>

      <NavLink to="btc-live">BTC Live</NavLink>
    </StyledMenu>
  );
};

Menu.propTypes = {
  open: bool.isRequired,
};

export default Menu;
