import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

const Container = styled.div`
  /* text-align: right; */
  display: flex;
  flex-direction: row-reverse;
  height: 1rem;
`;

const LoggedInCard = styled.div`
  display: flex;
  flex-direction: row;
`;

const UserInfoText = styled.div`
  padding-right: 1rem;
  @media (max-width: 700px) {
    display: none;
  }
`;

const AuthBar = () => {
  const { loginWithRedirect, logout, user, isLoading } = useAuth0();

  return (
    <Container>
      {!isLoading && !user && (
        <button className="btn btn-primary" onClick={() => loginWithRedirect()}>
          Log In
        </button>
      )}
      {!isLoading && user && (
        <LoggedInCard>
          <UserInfoText>
            <Link to="/profile">Logged in as {user.name}</Link>
          </UserInfoText>
          {/* TODO: do smaller profile button on mobile */}
          <button
            className="btn btn-error"
            onClick={() =>
              logout({
                returnTo: window.location.origin,
              })
            }
          >
            Log Out
          </button>
        </LoggedInCard>
      )}
    </Container>
  );
};

export default AuthBar;
