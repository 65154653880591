import styled from "styled-components";
import { useState, useEffect } from "react";

const ProgressBar = styled.div`
  text-align: center;
  width: 70vw;
  margin-top: 2rem;

  @media (max-width: 650px) {
    margin-top: 1.5rem;
  }
`;

const LoadingBar = ({ message }) => {
  const [loadPct, setLoadPct] = useState(0);
  const [loop, setLoop] = useState({}); // this is a hack TODO: useRef

  useEffect(() => {
    // set up loading bar loop
    loop.intervalId = setInterval(() => {
      setLoadPct((prevCount) => {
        if (prevCount < 100) return prevCount + 20;
        else {
          clearInterval(loop.intervalId);
          return prevCount;
        }
      });
    }, 100); // how fast progress bar should go

    return () => {
      clearInterval(loop.intervalId);
    };
  }, []);

  return (
    <ProgressBar>
      {message && <p>{message}</p>}
      <div className="progress-bar progress-bar-show-percent">
        <div
          className="progress-bar-filled"
          style={{ width: `${loadPct}%` }}
          data-filled={`Loading ${loadPct}%`}
        ></div>
      </div>
    </ProgressBar>
  );
};

export default LoadingBar;
