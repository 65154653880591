import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-flexbox-grid";
import { useState, useEffect } from "react";
import fillerimage1 from "./fillerimages/fillerimage1.jpg";
import { useSelector, useDispatch } from "react-redux";

const NewsArticlePage = styled.div`
  margin: auto;
  width: 1150px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 1200px) {
    width: 930px;
  }

  @media (max-width: 991px) {
    width: 740px;
  }
  @media (max-width: 767px) {
    width: 95%;
  }
  @media (max-width: 650px) {
  }

  @media (max-width: 450px) {
    width: 98%;
  }
`;

const NewsArticleContainer = styled.div`
  width: 70%;

  @media (max-width: 1200px) {
  }

  @media (max-width: 991px) {
    width: 100%;
  }
  @media (max-width: 767px) {
  }
  @media (max-width: 650px) {
  }

  @media (max-width: 450px) {
  }
`;

const TopRow = styled(Row)`
  width: 100%;
  margin: auto;
  padding: 1rem 0 0.3rem 0;
  font-size: 0.9rem;
  font-weight: 100;
`;

const ArticleTopRow = styled(TopRow)`
  display: flex;
  ${"" /* justify-content: space-between; */}
  justify-content:flex-end;
`;

const EditorsTopRow = styled(TopRow)`
  display: flex;

  justify-content: center;
`;
const Title = styled.h2`
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
  margin: auto;
  padding: 0.5rem 0 1rem 0;
  @media (max-width: 1200px) {
  }

  @media (max-width: 991px) {
  }
  @media (max-width: 767px) {
  }
  @media (max-width: 650px) {
  }

  @media (max-width: 450px) {
  }
`;

const EditorsChoiceCol = styled.div`
  width: 30%;
  margin-left: 1.5rem;
`;

const NewsArticle = ({}) => {
  //in order to call redux action need to call dispatch hook
  const dispatch = useDispatch();
  const mockText = [
    " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. At augue eget arcu dictum varius duis at. Mauris sit amet massa vitae tortor condimentum lacinia. Et molestie ac feugiat sed lectus vestibulum. Amet consectetur adipiscing elit pellentesque habitant morbi.",
  ];

  const datePosted = ["Posted at 00:00"];
  const totalViews = ["000 Total views"];
  const mockExternalNews = [
    "Cointelegraph: Luna losses top $1B",
    "Blockchain security firm warns of new MetaMask phishing campaign,",
    "Tether calls thesis behind USDT short-selling 'flat out wrong'",
    "Tiffany & Co turning CryptoPunk NFTs into $50K custom pendants",
  ];
  return (
    <NewsArticlePage>
      {/* //TODO: make NewsArticleContainer into own component */}
      <NewsArticleContainer>
        <ArticleTopRow>
          {" "}
          {/* <span>{totalViews}</span>  */}
          <span>{datePosted}</span>{" "}
        </ArticleTopRow>
        <Title>News title</Title>
        <p>{mockText}</p>

        <Image src={fillerimage1} />

        <p> {mockText} </p>
        <br />
        <p>{mockText}</p>
        <br />
        <p>{mockText} </p>
        <br />
        <p>{mockText} </p>
      </NewsArticleContainer>
      {/* TODO: set up redux to store 5 most relevant external news stories here */}

      {/*  <EditorsChoiceCol>
        <EditorsTopRow>
          <span>Editor's Choice</span>{" "}
        </EditorsTopRow>
       {mockExternalNews.map((x) => (
          <ul>
            <li>{x}</li>
          </ul>
        ))} 
      </EditorsChoiceCol>
    */}
    </NewsArticlePage>
  );
};

export default NewsArticle;
