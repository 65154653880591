import React from "react";
import styled from "styled-components";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-flexbox-grid";

import {
  dayChartState,
  weekChartState,
  monthChartState,
} from "./redux/slices/timeCustomSlice";
const TimeContainer = styled(Row)`
  padding: 0rem 1rem;

  @media (min-width: 651px) {
  }
`;

const TimeButton = styled.button`
  height: 2.5rem;
  width: 2.5rem;
  color: #a3abba;
  font-weight: 500;
  margin-bottom: 1rem;
  border-radius: 50%;
  margin: 0rem auto auto auto;
  padding: 0;
  @media (max-width: 650px) {
    margin: 0.8rem auto auto auto;
  }
`;

const TimeCustomize = () => {
  const { timeRange } = useSelector((state) => state.time);

  //in order to call redux action need to call dispatch hook
  const dispatch = useDispatch();
  return (
    <TimeContainer>
      <TimeButton
        style={{
          backgroundColor: timeRange === "1D" ? "#3f3f44" : "transparent",
          color: timeRange === "1D" ? "white" : "#a3abba",
        }}
        className="btn btn-default"
        onClick={() => dispatch(dayChartState())}
      >
        1D
      </TimeButton>
      <TimeButton
        style={{
          backgroundColor: timeRange === "1W" ? "#3f3f44" : "transparent",
          color: timeRange === "1W" ? "white" : "#a3abba",
        }}
        className="btn btn-default"
        onClick={() => dispatch(weekChartState())}
      >
        1W
      </TimeButton>
      <TimeButton
        style={{
          backgroundColor: timeRange === "1M" ? "#3f3f44" : "transparent",
          color: timeRange === "1M" ? "white" : "#a3abba",
        }}
        className="btn btn-default"
        onClick={() => dispatch(monthChartState())}
      >
        1M
      </TimeButton>
    </TimeContainer>
  );
};

export default TimeCustomize;
