import * as React from "react";
import "./App.css";
import { Row, Col } from "react-flexbox-grid";
import { install } from "resize-observer";
import TimeCustomize from "./TimeCustomize";
import CryptoGraphSearch from "./CryptoGraphSearch";
import { useState, useEffect } from "react";
import { Chart as ChartJS, Chart, registerables, Tooltip } from "chart.js";
import styled from "styled-components";

import { Line, ToolTip } from "react-chartjs-2";
import { useSelector, useDispatch } from "react-redux";
import {
  setChartSymbol,
  setCoinName,
  setCoinColor,
} from "./redux/slices/chartSymbolSlice";
// const COIN_API_KEY = "ACD35BA4-5623-4E5B-B97C-C1A28AFB1438";
const CHART_API_KEY =
  "c297fbb1103968336c0bf54d6e0958244a501bef2f298bfc84e53e11e7f6ab06";

const WATCHLIST_API_KEY =
  "768dd429e59bf8cdee3b94fead85105f6cd7556ce5362991345fc59a9e80103f";
ChartJS.register(...registerables);

if (!window.ResizeObserver) install();

const TimeAndPrice = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1.5rem;
  background-color: #222225;
  margin: -2rem auto 0.5rem auto;
  text-align: center;
  display: flex;
  @media (max-width: 991px) {
    margin: -0.5rem auto 0.5rem auto;
  }
  @media (max-width: 650px) {
    margin: 0 auto 0.8rem auto;
    text-align: center;
    display: flex;
  }
`;

const TimeFrame = styled.span`
  font-size: 1.2rem;
`;

const CryptoButtons = styled.button`
  margin-left: 1rem;
  margin-right: 1rem;
  background-color: var(--secondary-color);
`;

const HomePage = styled(Col)`
  margin-top: 1rem;
  padding-left: 1.53rem;
  padding-right: 1.53rem;
  padding-bottom: 20px;

  @media (min-width: 1201px) {
    padding-left: 20%;
    padding-right: 20%;
  }

  @media (max-width: 991px) {
    padding-left: 0.9rem;
    padding-right: 0.9rem;
  }
  @media (max-width: 650px) {
    padding-left: 0.4rem;
    padding-right: 0.4rem;
  }
`;

const ChartCard = styled.div`
  padding: 1rem 1.4rem 1rem 1rem;
  margin-top: 2rem;
  border-radius: 50px;

  @media (max-width: 991px) {
    border-radius: 20px;
    padding: 0.8rem 1.1rem 0.8rem 0.8rem;
  }
  @media (max-width: 650px) {
    padding: 0.5rem 0.8rem 1rem 0.5rem;
    margin-top: 1rem;
  }
`;

const CryptoGraph = styled(Line)`
  display: flex;
  padding: 1rem;
  touch-action: none;

  @media (max-width: 991px) {
    padding: 0rem 1.5rem 2rem 1.5rem;
  }

  @media (max-width: 650px) {
    width: 100%;
    padding: 0;
  }
`;

const ChartHeaderSmall = styled.div`
  font-size: 2rem;

  @media (max-width: 650px) {
    font-size: 2rem;
  }
`;

const LatestTime = styled(Col)`
  margin: 0 0 0 0.15rem;
  @media (max-width: 650px) {
    display: none;
  }
`;

const Price = styled.p`
  font-size: 20px;
  margin: 0;
  width: fit-content;

  @media (max-width: 650px) {
    display: none;
  }
`;

const CoinLogo = styled.img`
  width: 90px;
  height: 100px;

  @media (max-width: 991px) {
    max-height: 80px;
  }
  @media (max-width: 650px) {
    max-width: 50px;
    max-height: 60px;
  }
`;

const ChartHeaderRow = styled.div`
  flex-direction: row;
  justify-content: space-evenly;
  width: fit-content;
  display: flex;
  align-items: center;
  margin: auto auto auto 0;

  @media (max-width: 650px) {
    margin-bottom: 0rem;
  }
`;

const CryptoHeaderText = styled.div`
  margin-left: 0rem;
  margin-top: -10px;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 650px) {
    padding: 0 1rem 0 0.2rem;
  }
`;

const CoinLogoCol = styled.div`
  padding-right: 0;
  margin: auto 0.3rem;

  @media (max-width: 650px) {
    margin: auto 0.15rem;
  }
`;

// const theme = {
//sets main theme

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

// Breakpoint configs: xs sm md lg
function Charts() {
  const dispatch = useDispatch();

  const chartDataCache = {}; // Cache object to store previously fetched chart data

  const { timeRange, apiPoints, graphtype, timeFrame } = useSelector(
    (state) => state.time
  );

  const chartSymbol = useSelector(
    (state) => state.cryptocurrencyData.chartSymbol
  );
  const coinName = useSelector((state) => state.cryptocurrencyData.coinName);
  const coinColor = useSelector((state) => state.cryptocurrencyData.coinColor);

  // const [newColor, setNewColor] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [isHovering, setIsHovering] = useState(false);
  const [coinData, setCoinData] = useState({
    times: [],
    prices: [],
  });

  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const fetchChartData = async (symbol, apiPoints, graphtype, timeRange) => {
    const cacheKey = `${symbol}-${apiPoints}-${graphtype}-${timeRange}`;
    if (chartDataCache[cacheKey]) {
      return chartDataCache[cacheKey];
    }
    const response = await fetch(
      `https://min-api.cryptocompare.com/data/v2/${graphtype}?fsym=${symbol}&tsym=USD&limit=${apiPoints}&api_key=${CHART_API_KEY}`
    );
    console.log("apiPoints : ", { apiPoints });

    const json = await response.json();
    const data = json.Data.Data;
    console.log("data", data);
    // TODO: should we "fix" times here?
    console.log(`received ${data.length} datapoints`);

    const times = data.map((obj, i) => {
      if (timeRange === "1D") {
        return new Date(obj.time * 1000).toLocaleTimeString(
          navigator.language,
          {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          }
        );
      } else if (timeRange === "1W") {
        return new Date(obj.time * 1000).toLocaleTimeString(
          navigator.language,
          { weekday: "short", hour: "numeric" }
        );
      } else if (timeRange === "1M") {
        return new Date(obj.time * 1000).toLocaleTimeString(
          navigator.language,
          {
            month: "short",
            day: "numeric",
            hour: "numeric",
          }
        );
      }
    });
    // price is fine as is
    const prices = data.map((obj) => obj.high);
    console.log("prices:", prices);

    const chartData = {
      times,
      prices,
    };

    // Store the data in the cache for next time function is ran
    chartDataCache[cacheKey] = chartData;

    return chartData;
  };
  //the time and date values are stored in these from tooltip (TT)
  const [cryptoTTTime, setCryptoTTTime] = useState("");
  const [cryptoTTPrice, setCryptoTTPrice] = useState(0);

  // this is kind of a hack to update intervalId in state for the effect cleanup hook...
  const [loop, setLoop] = useState({});
  const [windowSize, setWindowSize] = useState(getWindowSize());
  var isTouchDevice = "ontouchstart" in document.documentElement;

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest(".search-box")) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // this updates state

  const doFetch = async () => {
    console.log("!!! doFetch !!!");

    setCoinData(
      await fetchChartData(chartSymbol, apiPoints, graphtype, timeRange)
    );

    console.log(coinData);
  };

  useEffect(() => {
    document.title = "d2index | Charts";
    setIsDropdownOpen(false);
    doFetch();
    console.log("setInterval");
    loop.intervalId = setInterval(() => {
      console.log("~ 1-min interval API call hit ~");
      doFetch();
    }, 60 * 1000);
    console.log("loop.intervalId", loop.intervalId);

    return () => {
      console.log("chart clean up", loop);
      clearInterval(loop.intervalId);
    };
  }, [apiPoints, chartSymbol]);

  const hasData = coinData.times.length > 0;

  //positions the time above the dashed line when hovering/dragging
  Tooltip.positioners.myCustomPositioner = function (elements, eventPosition) {
    const pos = Tooltip.positioners.average(elements);

    // Happens when nothing is found
    if (pos === false) {
      return false;
    }

    const chart = this.chart;

    return {
      x: pos.x,
      y: chart.chartArea.top,
      xAlign: "center",
      yAlign: "top",
      marginTop: "2rem",
    };
  };

  function cssvar(name) {
    return getComputedStyle(document.documentElement).getPropertyValue(name);
  }
  const getChartData = (data, color) =>
    hasData
      ? {
          labels: data.times,
          datasets: [
            {
              label: "$",
              data: data.prices,
              backgroundColor: (context) => {
                const ctx = context.chart.ctx;
                const chartArea = context.chart.chartArea;
                const gradient = ctx.createLinearGradient(
                  0,
                  0,
                  0,
                  chartArea?.bottom || 0
                );
                gradient.addColorStop(0, `rgba(${color},1)`);
                {
                  windowSize.innerWidth < 650 &&
                    gradient.addColorStop(0, `rgba(${color},0)`);
                }
                {
                  windowSize.innerWidth >= 650 &&
                    gradient.addColorStop(0.1, `rgba(${color},.3)`);
                }
                return gradient;
              },
              borderColor: `rgba(${color},1)`,
              borderJoinStyle: "round",
              borderCapStyle: "round",
              borderWidth: 2,
              pointRadius: 0,
              pointHitRadius: 10,
              lineTension: 0.2,
              fill: true,
            },
          ],
        }
      : { datasets: [] };

  const chartOptions =
    windowSize.innerWidth < 650
      ? {
          layout: {
            padding: {
              top: 20,
            },
          },
          elements: {
            point: {
              borderWidth: 0,
              radius: 0,
              hoverRadius: 0,
              backgroundColor: "rgba(0,0,0,0)",
              hitRadius: 0,
            },
          },
          responsive: true, // ?
          plugins: {
            decimation: "decimation",
            tooltip: {
              backgroundColor: "rgba(0,0,0,0)",
              bodyColor: "rgba(0,0,0,0)",
              opacity: 0,
              displayColors: false,
              position: "myCustomPositioner",
              pointColor: "rgba(0,0,0,0)",
              caretPadding: -220, //positions time above graph
              padding: {
                bottom: 10,
              },
              bodyFont: {
                family: "'Consolas', 'Menlo', 'Monaco', 'Lucida Console'", // Add your font here to change the font of your tooltip body
              },
              titleFont: {
                family: "'Consolas', 'Menlo', 'Monaco', 'Lucida Console'", // Add your font here to change the font of your tooltip title
              },

              callbacks: {
                afterFooter: function (chart) {
                  setCryptoTTPrice(
                    chart[0].raw.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  );

                  setCryptoTTTime(chart[0].label);
                },
              },
            },
            hover: {
              mode: "nearest",
              intersect: false,
            },
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
          },

          scales: {
            x: {
              ticks: {
                display: false,
                maxRotation: 0,
                autoSkip: true,
              },

              grid: {
                display: false, //removes x grid lines on chart on small screens
              },
            },

            y: {
              ticks: {
                display: false,
              },
              grid: {
                display: false, //removes y grid lines on chart on small screens
              },
            },
          },
        }
      : {
          elements: {
            point: {
              borderWidth: 2,
              radius: 4,
              hoverRadius: 5,
              backgroundColor: "rgba(0,0,0,0)",
              hitRadius: 0,
            },
          },
          responsive: true, // ?
          plugins: {
            decimation: "decimation",

            tooltip: {
              backgroundColor: "rgba(0,0,0,0)",
              bodyColor: "rgba(0,0,0,0)",
              opacity: 0,
              displayColors: false,
              position: "myCustomPositioner",
              pointColor: "rgba(0,0,0,0)",
              caretPadding: -220, //positions time above graph
              padding: {
                bottom: 20,
              },
              bodyFont: {
                family: "'Consolas', 'Menlo', 'Monaco', 'Lucida Console'", // Add your font here to change the font of your tooltip body
                size: 15,
              },
              titleFont: {
                family: "'Consolas', 'Menlo', 'Monaco', 'Lucida Console'", // Add your font here to change the font of your tooltip title
                size: 15,
              },
              callbacks: {
                afterFooter: function (chart) {
                  setCryptoTTPrice(
                    chart[0].raw.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  );

                  setCryptoTTTime(chart[0].label);
                },
              },
            },
            hover: {
              mode: "nearest",
              intersect: false,
            },
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
          },

          scales: {
            x: {
              ticks: {
                display: false,
              },
              grid: {
                display: false, //removes x grid lines on chart on small screens
              },
            },
            y: {
              ticks: {
                display: false,
              },
              grid: {
                display: false, //removes y grid lines on chart on small screens
              },
            },
          },
        };
  const btcLatestPrice = hasData
    ? `$${coinData.prices[coinData.prices.length - 1].toLocaleString(
        undefined,
        {
          minimumFractionDigits: 2,
        }
      )}`
    : "";
  const coinDataLatestTime = hasData
    ? coinData.times[coinData.times.length - 1]
    : "";

  //formatting for image src paths
  const lowerCaseSym = chartSymbol.toLowerCase();
  const lowerCaseFullName = coinName.toLowerCase().replace(/ /g, "-");

  const sharedChartSettings = {
    options: {
      ...chartOptions,
      interaction: {
        mode: "index",
        intersect: false,
      },
    },
  };
  return (
    <HomePage>
      <div className="search-box">
        <CryptoGraphSearch
          isDropdownOpen={isDropdownOpen}
          setIsDropdownOpen={setIsDropdownOpen}
        />
      </div>

      <ChartCard
        style={{ boxShadow: `0px 0px 35px 8px rgba(${coinColor}, 35%)` }}
      >
        <ChartHeaderRow>
          <CoinLogoCol>
            <CoinLogo
              src={`https://cryptologos.cc/logos/${lowerCaseFullName}-${lowerCaseSym}-logo.png?v=025`}
            />
          </CoinLogoCol>

          <CryptoHeaderText xs={9} sm={10} md={11} lg={11}>
            <ChartHeaderSmall>{coinName}</ChartHeaderSmall>

            <LatestTime> {coinDataLatestTime}</LatestTime>
          </CryptoHeaderText>
        </ChartHeaderRow>

        <TimeAndPrice>
          {isHovering ? (
            <span>${cryptoTTPrice} </span>
          ) : (
            <span>{btcLatestPrice} </span>
          )}
          <TimeFrame style={{ marginTop: 3 }}>{timeFrame}</TimeFrame>
        </TimeAndPrice>

        <Row>
          <CryptoGraph
            data={getChartData(coinData, coinColor)}
            {...sharedChartSettings}
            onTouchMove={isTouchDevice ? handleMouseOver : null} //for touch screens
            onTouchEnd={isTouchDevice ? handleMouseOut : null} //for touch screens
            onMouseOver={isTouchDevice ? null : handleMouseOver} //for desktop
            onMouseOut={isTouchDevice ? null : handleMouseOut} //for desktop
          />
        </Row>
        <TimeCustomize />
      </ChartCard>
    </HomePage>
  );
}

export default Charts;
