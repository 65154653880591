import styled from "styled-components";

export const StyledBurger = styled.button`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  margiin: auto;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  span {
    width: 2rem;
    height: 0.15rem;
    background-color: white;
    border-radius: 10px;
    transition: all 0.1s linear;
    position: relative;
    transform-origin: 1px;
    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      ${
        "" /* transform: ${({ open }) => (open ? "translateX(0px)" : "translateX(50)")}; */
      }
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
  display: none;
  font-size: 2rem;

  @media (max-width: 991px) {
    display: flex;
  }
`;
