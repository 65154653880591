import { Outlet } from "react-router-dom";
import { Grid } from "react-flexbox-grid";
import styled from "styled-components";

import Header from "./Header";
import AuthBar from "./AuthBar";
import Footer from "./Footer";

const StyledGrid = styled(Grid)`
  padding: 0 !important;
`;

function Layout() {
  return (
    <StyledGrid fluid>
      <Header />
      <AuthBar />
      <Outlet />
      <Footer />
    </StyledGrid>
  );
}

export default Layout;
