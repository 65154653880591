import { useState, useEffect } from "react";
import styled from "styled-components";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { API_URL } from "./config";

const InsideRowDiv = styled.div`
  padding-right: 2rem;
`;

const Profile = () => {
  const [apiData, setApiData] = useState(null);
  const { getAccessTokenSilently, user } = useAuth0();
  const { name, picture, email } = user;

  const getTestAuthAPI = async () => {
    setApiData(null);
    const token = await getAccessTokenSilently();
    const response = await fetch(`${API_URL}/test-auth`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // TODO: catch error here...
    const json = await response.json();
    if (json.status === "OK") {
      setApiData("Auth API successful!");
    }
  };

  // TODO: this is just a mocked out debug profile view
  // add test call to secure API endpoint:
  // https://auth0.com/blog/complete-guide-to-react-user-authentication/#Calling-an-API
  return (
    <div>
      <div className="row align-items-center profile-header">
        <div className="col-md-2 mb-3">
          <img
            src={picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </div>
        <div className="col-md text-center text-md-left">
          <h2>{name}</h2>
          <p className="lead text-muted">{email}</p>
        </div>
      </div>
      <div className="row">
        <pre className="col-12 text-light bg-dark p-4">
          {JSON.stringify(user, null, 2)}
        </pre>
      </div>
      <div className="row">
        <InsideRowDiv>
          <button className="btn btn-primary" onClick={getTestAuthAPI}>
            Test Auth API
          </button>
        </InsideRowDiv>
        <InsideRowDiv>
          <div>{apiData}</div>
        </InsideRowDiv>
      </div>
    </div>
  );
};

export default withAuthenticationRequired(Profile, {
  onRedirecting: () => <div>Loading...</div>,
});
